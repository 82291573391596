import React from 'react';
import { Link } from 'react-router-dom';
import WidgetPost from './widget/WidgetPost';
import WidgetCategory from './widget/WidgetCategory';
import { FaSearch, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaBehance, FaYoutube, FaSkype} from "react-icons/fa";

const BlogSidebar = () => {
    return (
        <div className="axil-sidebar">
            {/* <div className="widget widget-search">
                <h4 className="widget-title">Search</h4>
                <form action="#" className="blog-search">
                    <input type="text" placeholder="Search…" />
                    <button className="search-button"><FaSearch /></button>
                </form>
            </div> */}
            {/* <div className="widget widget-categories">
                <h4 className="widget-title">Categories</h4>
                <WidgetCategory />
            </div> */}
            <div className="widget widge-social-share">
                <div className="blog-share">
                    <h5 className="title">Follow:</h5>
                    <ul className="social-list list-unstyled">
                    <li>
                        <a href="https://www.facebook.com/OdhavTechnologies" target="_blank"><FaFacebookF /></a></li>
                        <li><a href="https://join.skype.com/invite/XgK2S5ptfxxd" target="_blank"><FaSkype /></a></li>
                        <li><a href="https://www.instagram.com/odhavtechnologies" target="_blank"><FaInstagram /></a></li>
                        {/* <li><a href="https://www.behance.net/gallery/159120637/3-Way-Brand-Identity" target="_blank"><FaBehance /></a></li> */}
                        <li><a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGoEUmj62pA9QAAAXxFE36wPUO73EsfWXKyR4lEY5Ug-M1EgmUZfTpj4ufFpS3OSAiQ8FRba4i0Gh17n-TrmjiISr06gYsTlZckG3_kB1xFNQK3e24mAtCt7Ce35vb3c8c-SHU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fodhav-technologies-539885219" target="_blank"><FaLinkedinIn /></a></li>
                        {/* <li><a href="https://www.youtube.com/channel/UCDnbDSLFKDLlWmvzjaaWB_g" target="_blank"><FaYoutube /></a></li> */}
                    </ul>
                </div>
            </div>
            <div className="widget widget-recent-post">
                <h4 className="widget-title">Recent post</h4>
                <WidgetPost />
            </div>
            <div className="widget widget-banner-ad">
                <Link to="/contact">
                    <img src={process.env.PUBLIC_URL + "/images/blog/contact_us.gif"} alt="Odhav technologies" />
                </Link>
            </div>
        </div>
    )
}

export default BlogSidebar;