import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";
import device from "../../../assets/dedicated_icons/device.svg";

function Project() {
  return (
    <section>
      <div className="container">
        <div className="row d-flex justify-content-center pt-5">
          <div className="col-lg-6">
            <div className="row ps-3">
              <div className="col-lg-3 col-3 d-flex align-items-end project-back-line-col">
                <div className="col-lg-3 col-3 project-back-line"></div>
              </div>
              <span className="col-lg-6 col-10">We dream big for you</span>
            </div>
            <h3 className="pt-3">Hire Dedicated Developers for your Project</h3>
            <p>
              Definitely product development can't be left behind schedule while
              you are occupied finding the developers with the required talents.
              With <b>Odhav Technologies</b> you can hire dedicated developers an hourly,
              full-time, or part-time basis with different skills for
              entrepreneurs looking to develop a full-stack web or mobile app
              project. We set up remote dedicated development teams who easily
              cope with evolving needs and fast deliveries. By hiring dedicated
              developers with our flexible hiring models you get complete
              command over your project plan and progress while making the
              product release quick - and turning off the back burner.
            </p>
            <div className="d-flex justify-content-center pt-3">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="round-wrap d-flex align-items-center justify-content-center">
                    <div className="project-circle-sm d-flex align-items-center justify-content-center">
                      <img src={device} className="project-phone-img"></img>
                    </div>
                    <div className="round"></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="number_developers ">
                    <div className="row d-flex align-items-center pt-4">
                      <div>
                        <h5 className="text-white">10+</h5>
                        <h5 className="text-white">Developers</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Project;
