import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';
import VideoOne from '../component/video/VideoOne';


const AboutUs = () => {

    return (
        <>
        <SEO title="About us" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="A team of Creative Minds designing best Apps and Website for the World."
                paragraph ="At Odhav technologies, we make sure that alongside growth of the company, our employees and client grow by providing a great work-life Culture in the Industry."
                styleClass="thumbnail-4"
                
                />
                <AboutFour />
                <AboutThree />
                <AboutFive />
                {/* <VideoOne link="https://www.youtube.com/watch?v=9h6q5SF1gck" thumb="https://i.ytimg.com/vi_webp/9h6q5SF1gck/maxresdefault.webp" /> */}
                <ProcessOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;