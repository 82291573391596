import React from 'react';
import {Link, useParams} from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import VideoOne from '../component/video/VideoOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe, FaCheckCircle } from 'react-icons/fa';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

const allProjectData = ProjectData;

const ProjectDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;

    const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    const detailsProject = getProjectData[0];


    return (
        <>
        <SEO title="Project Details"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerTwo 
            title={detailsProject.title}
            paragraph ={detailsProject.excerpt}
            mainThumb={detailsProject.image}
            />
            <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">
                                <FaCheckCircle color='green' /> We delivered - {detailsProject.category.map((cat, i) => (
                                            <span key={i}>{cat}</span> 
                                        )
                                    )} 
                                </span>
                                <h3 className="title">{detailsProject.title}</h3>
                            </div>
                            {detailsProject.body.map((para, index) => (
                                <p key={index} dangerouslySetInnerHTML={{__html: para}}></p>
                            ))}
                            {detailsProject.link ? <Link to={detailsProject.link} className="axil-btn btn-fill-primary" target="_blank" rel="noopener noreferrer">check it out</Link> : null}
                            
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="why-choose-us">
                                <div className="section-heading heading-left">
                                    <h3 className="title">Process</h3>
                                    <p>When it comes to building a website, mobile application, UIUX or branding, there are three key phases: </p>
                                </div>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><FaCompress /> Strategy</Accordion.Header>
                                        <Accordion.Body>
                                        The strategy phase involves understanding the purpose of the website, identifying the target audience, and creating a plan to achieve the website's goals. This includes defining the website's content, functionality, and structure. The strategy phase is essential as it lays the foundation for the rest of the project and ensures that the website will effectively meet the needs of its users.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FaCode /> Design</Accordion.Header>
                                        <Accordion.Body>
                                        The design phase involves creating the visual layout of the website, including its color scheme, typography, and imagery. The design should be visually appealing and reflect the brand's identity. The design phase also includes creating wireframes and prototypes, which help to refine the website's layout and functionality before it is developed.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><FaGlobe /> Development</Accordion.Header>
                                        <Accordion.Body>
                                        The development phase involves turning the design into a functional website. This includes writing code, integrating third-party services, and testing the website to ensure that it works correctly on all devices and browsers. The development phase is where the website's features and functionality are built, such as contact forms, e-commerce capabilities, and content management systems.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* {detailsProject.video ? <VideoOne link={detailsProject.video} thumb={detailsProject.image} /> : <VideoOne link="https://www.youtube.com/watch?v=9h6q5SF1gck" thumb="https://i.ytimg.com/vi_webp/9h6q5SF1gck/maxresdefault.webp" />} */}
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ProjectDetails;