import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";

function Dedicated() {
  return (
    <section className="dedicated-model">
      <div className="dedicated-bg"></div>
      <div className="container pt-5">
        <div className="row">
          <div className="row">
            <div className="col-lg-5">
              <div className="row ps-3">
                <div className="col-lg-3 col-3 d-flex align-items-end project-back-line-col">
                  <div className="col-lg-3 col-3 project-back-line"></div>
                </div>
                <span className="col-lg-6 col-10">Hire Best Resources</span>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-lg-4 d-flex align-items-center">
              <h3 className="pt-4 font-size">
                Our Hiring Dedicated Developers Models
              </h3>
            </div>
            <div className="col-lg-8 d-flex align-items-center">
              <h5 className="pt-4">
                We deploy <span>top-notch</span> agile teams who define and
                enable your vision on-demand.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-lg-4 col-md-4 pb-4 d-flex justify-content-center">
          <div class="dedicated-card-container ">
            <div class="dedicated-timing-details">
              <h5 className="text-start ps-4 pt-3 card-font">Full Time</h5>
            </div>
            <div class="dedicated-card-bg">
              <div className="timing-details">
                <div className="time-icon">
                  <div className="timer-icon-time1"></div>
                </div>
                <div className="hours-per-day">
                  <h6 className="time-font">Hours Per Day</h6>
                  <span className="font-weight-bold">
                    <strong className="hours-bg time-font">8</strong> hrs/day
                  </span>
                </div>
                <div className="minimum-day">
                  <h6 className="time-font">Minimum Days</h6>
                  <span className="font-weight-bold">
                    <strong className="hours-bg time-font">30</strong> days
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 pb-4 d-flex justify-content-center">
          <div class="dedicated-card-container ">
            <div class="dedicated-timing-details-2">
              <h5 className="text-start ps-4 pt-3 card-font">Part Time</h5>
            </div>
            <div class="dedicated-card-bg">
              <div className="timing-details">
                <div className="time-icon">
                  <div className="timer-icon-time-2"></div>
                </div>
                <div className="hours-per-day">
                  <h6 className="time-font">Hours Per Day</h6>
                  <span className="font-weight-bold">
                    <strong className="hours-bg-2 time-font">4</strong> hrs/day
                  </span>
                </div>
                <div className="minimum-day">
                  <h6 className="time-font">Minimum Days</h6>
                  <span className="font-weight-bold">
                    <strong className="hours-bg-2 time-font">30</strong> days
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 pb-4 d-flex justify-content-center">
          <div class="dedicated-card-container ">
            <div class="dedicated-timing-details-3">
              <h5 className="text-start ps-4 pt-3 card-font">Hourly</h5>
            </div>
            <div class="dedicated-card-bg">
              <div className="timing-details">
                <div className="time-icon">
                  <div className="timer-icon-time-2"></div>
                </div>
                <div className="hours-per-day">
                  <h6 className="time-font">Hours Per Day</h6>
                  <span className="font-weight-bold">
                    <strong className="hours-bg-3 time-font">Flexible</strong>
                    <br></br>hrs/day
                  </span>
                </div>
                <div className="minimum-day">
                  <h6 className="time-font">Minimum Hours</h6>
                  <span className="font-weight-bold">
                    <strong className="hours-bg-3 time-font">50</strong> Hours
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center pt-5">
        <div className="d-flex align-items-center justify-content-center">
          <button className="text-white fw-bolder button-bg">Hire Now</button>
        </div>
      </div>
    </section>
  );
}

export default Dedicated;
