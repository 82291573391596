import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaBehance, FaPhone, FaFax, FaSkype, FaInstagram } from "react-icons/fa";


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><a href="https://odhavtechnologies.com/service" target="_blank">Website Development</a></li>
                            <li><a href="https://odhavtechnologies.com/service" target="_blank">Mobile App Development</a></li>
                            <li><a href="https://odhavtechnologies.com/service" target="_blank">ERP Solutions</a></li>
                            <li><a href="https://odhavtechnologies.com/service" target="_blank">UI/UX</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    {/* <span className="title">Contact Information</span> */}
                                    {/* <p>503, Abhishree Adroit, Vastrapur<br /> Ahmedabad, India</p> */}
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <a href="tel:+919998692099" className="tel"><FaPhone /> (+91) 9265753149</a>
                                    <a href="tel:+918155881434" className="tel"><FaPhone /> (+91) 9586592853</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://facebook.com/OdhavTechnologies" target="_blank"><FaFacebookF /></a>
                                        </li>

                                        <li>
                                            <a href="https://join.skype.com/invite/XgK2S5ptfxx" target="_blank"><FaSkype /></a>
                                        </li>
                                        <li>
                                           <a href="https://www.instagram.com/odhavtechnologies" target="_blank"><FaInstagram /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGoEUmj62pA9QAAAXxFE36wPUO73EsfWXKyR4lEY5Ug-M1EgmUZfTpj4ufFpS3OSAiQ8FRba4i0Gh17n-TrmjiISr06gYsTlZckG3_kB1xFNQK3e24mAtCt7Ce35vb3c8c-SHU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fodhav-technologies-539885219" target="_blank"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
