import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/others/met.png"} alt="founders - Odhav technologies" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Our Team</span>
                        <h2>Alone we can do so little; together we can do so much.</h2>  
                        <p>Our team is comprised of highly skilled and dedicated professionals who are passionate about using technology to solve complex problems. We believe in collaboration, creativity, and continuous learning, and we work together to deliver innovative solutions that exceed our clients' expectations.</p>
                        <p>With a diverse range of backgrounds and experiences, our team brings a wealth of knowledge and expertise to every project we undertake. We pride ourselves on our ability to work seamlessly together, sharing ideas and supporting each other to deliver exceptional results. When you work with our team, you can be confident that you are working with some of the best and brightest minds in the industry, all committed to achieving our common goals.</p>
                        {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">Our Team</Link> */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;