import React from 'react'
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";

const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} | Odhav technologies </title>
            <meta name="robots" content="noindex, follow" />
            <meta name="keywords" content="Web Development, Web Development company, Web Development agency, App Development company, Mobile App Development company, Mobile Application Development company, Mobile App Development agency, Web Design Company in Bhuj & Ahmedabad , Web Development Company in Bhuj & Ahmedabad , Website Design Company in Bhuj & Ahmedabad , Website Development Company in Bhuj & Ahmedabad , web designing company in Bhuj & Ahmedabad , Website design, Mobile app development, React development, Front-end development, Back-end development, UI/UX design, E-commerce development, Custom software development, Web development services, Mobile app design, Cross-platform development, iOS app development, Android app development, Hybrid app development, Agile development, Cloud-based development, Blockchain development, Artificial intelligence development, Machine learning development, Cybersecurity."></meta>
            <meta name="description" content="Looking for a reliable and experienced website and mobile application development company? Look no further than our team of experts. We specialize in creating custom solutions that are tailored to your business needs, using the latest technology and industry best practices. Our services include web design, mobile app development, e-commerce solutions, and more. " />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/24287318.js"></script>
            </Helmet>
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;