import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../../assets/css/dedicated.css";
import ExpertiseList from "./experties_list";

function Domain() {
  const experise_list = ExpertiseList();
  return (
    <section>
      <div className="container pt-5">
        <div className="row">
          <div className="row">
            <div className="col-lg-5">
              <div className="row ps-3">
                <div className="col-lg-3 col-3 d-flex align-items-end project-back-line-col">
                  <div className="col-lg-3 col-3 project-back-line"></div>
                </div>
                <span className="col-lg-6 col-10 fw-bolder fst-italic">
                  Power Your Industry
                </span>
              </div>
            </div>
            <h3 className="ps-3 pt-4">Domain Expertise</h3>
          </div>
          <div className="row">
            {experise_list.map((data) => {
              return (
                <div className="col-lg-3 col-6 domain-container-height">
                  <div className="domain-img-bg">
                    <div className="domain-main-container d-flex justify-content-center align-items-center flex-column">
                      <div>
                        <img src={data.img} className="domain-img-set"></img>
                      </div>
                      <h5 className="pt-4">{data.text}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Domain;
