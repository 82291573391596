import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../assets/css/web.css";
import WebCards from "./web_design_cards";

function Design() {
  const card_list = WebCards();
  return (
    <section>
      <div className="section-padding development-section">
        <div className="row pt-5">
          <div className="d-flex justify-content-center pb-5">
            <div className="col-xl-9 col-lg-10 col-md-9 col-sm-12 col-12 text-center">
              <h2 className="web-text">
                A super creative web design process that makes us different
              </h2>
              <p className="web-content">
                As a web design agency in NYC, we engage in a strategy workshop,
                user personas, information architecture, pixel-perfect icons,
                and user interface. We follow every web design process very
                diligently. As a result, we can develop a mobile-friendly B2B
                web design that satisfies the user.
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div className="row web-development-container">
              {card_list.map((data) => {
                return (
                  <div class="col-lg-4 col-md-6 pe-2">
                    <div className="card card-development">
                      <div class="card-body">
                        <h4 class="card-title text-black">{data.text}</h4>
                        <p class="card-text text-black pb-2">{data.content}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-center">
            <button className="btn btn-dark">Design your website</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Design;
